<template>
    <div
        v-if="loggedIn"
        class="mb15 md-layout-item md-xlarge-size-100 md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 text-left"
    >
        <div class="carrier-content">
            <div>
                <md-avatar class="mr15">
                    <img
                        v-if="currentUser.avatar"
                        :src="`${baseUrl}${currentUser.avatar}`"
                        alt="Avatar"
                    />
                    <span v-if="!currentUser.avatar" class="first_logo">
                        {{ firstLetterUppercase }}
                    </span>
                </md-avatar>
                <div style="display: flex; flex-direction:column;">
                    <h2
                        v-if="currentUser.company_name.length > 11"
                        class="h2 text-gradient"
                    >
                        {{ currentUser.company_name.substring(0, 11) }}
                    </h2>
                    <h2 v-else class="h2">
                        {{ currentUser.company_name }}
                    </h2>

                    <div class="dib">
                        <img
                            v-if="currentUser.medal"
                            :src="
                                require('@assets/images/' +
                                    currentUser.medal +
                                    '.svg')
                            "
                            alt="medal"
                        />
                        <img
                            v-if="currentUser.payment_type"
                            :src="require('@assets/images/icon-vip.svg')"
                            alt="vip"
                        />

                        <md-button
                            v-if="!currentUser.medal"
                            class="buy-medal uppercase"
                            @click="
                                () => {
                                    showMedalsModal = true;
                                }
                            "
                            >{{ $t('global.chooseMedal') }}</md-button
                        >
                    </div>
                </div>
            </div>
            <div class="content-right">
                <div
                    class="pointer"
                    style="display: flex; flex-direction:column;"
                    @click="
                        () => {
                            showPriceModal = true;
                        }
                    "
                >
                    <div class="rate">{{ $t('route.rate') }}</div>
                    <div class="rate-score">
                        <div>
                            {{ currentUser.default_call_price }}/{{
                                currentUser.default_take_price
                            }}
                        </div>
                        <div>
                            <img
                                v-if="rateUp"
                                :src="require('@assets/images/icon_up.svg')"
                            />
                            <img
                                v-else
                                :src="require('@assets/images/icon_down.svg')"
                            />

                            <span
                                :class="{
                                    green: rateUp,
                                    red: !rateUp
                                }"
                                >{{
                                    Math.round(currentUser.price_percent)
                                }}%</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <price-modal
                :show="showPriceModal"
                @closePriceModal="() => (showPriceModal = false)"
            />

            <too-little-credit-modal
                :show="showMinCreditModal"
                :carrierUUID="currentUser.carrierUUID"
                @closeTooLittleCredit="() => (showMinCreditModal = false)"
            />

            <medals-modal
                :show="showMedalsModal"
                @close="() => (showMedalsModal = false)"
                @showMinCreditModal="e => (showMinCreditModal = e)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '@config';
import PriceModal from '../modals/pricesModal';
import TooLittleCreditModal from '../modals/tooLittleCreditModal';
import MedalsModal from '../modals/medalsModal';

export default {
    components: {
        PriceModal,
        TooLittleCreditModal,
        MedalsModal
    },

    data() {
        return {
            showMedalsModal: false,
            showPriceModal: false,
            showMinCreditModal: false,
            minCredit: 55
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            loggedIn: 'auth/loggedIn'
        }),

        firstLetterUppercase() {
            return this.currentUser.company_name.substring(0, 1).toUpperCase();
        },

        baseUrl() {
            return config.mediaBaseUrl;
        },

        rateUp() {
            const {
                price_percent: pricePercent,
                price_percent_old: pricePercentOld
            } = this.currentUser;

            if (pricePercent > pricePercentOld) {
                return true;
            } else {
                return false;
            }
        }
    },

    methods: {
        checkCredit() {
            if (this.currentUser.credit < this.minCredit) {
                this.showMinCreditModal = true;
            }
        },

        openTolittleModal() {
            this.showMinCreditModal = true;
        }
    },

    mounted() {
        if (this.loggedIn) {
            this.checkCredit();
        }
    }
};
</script>
