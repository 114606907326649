var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loggedIn)?_c('div',{staticClass:"mb15 md-layout-item md-xlarge-size-100 md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 text-left"},[_c('div',{staticClass:"carrier-content"},[_c('div',[_c('md-avatar',{staticClass:"mr15"},[(_vm.currentUser.avatar)?_c('img',{attrs:{"src":("" + _vm.baseUrl + (_vm.currentUser.avatar)),"alt":"Avatar"}}):_vm._e(),(!_vm.currentUser.avatar)?_c('span',{staticClass:"first_logo"},[_vm._v(" "+_vm._s(_vm.firstLetterUppercase)+" ")]):_vm._e()]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[(_vm.currentUser.company_name.length > 11)?_c('h2',{staticClass:"h2 text-gradient"},[_vm._v(" "+_vm._s(_vm.currentUser.company_name.substring(0, 11))+" ")]):_c('h2',{staticClass:"h2"},[_vm._v(" "+_vm._s(_vm.currentUser.company_name)+" ")]),_c('div',{staticClass:"dib"},[(_vm.currentUser.medal)?_c('img',{attrs:{"src":require('@assets/images/' +
                                _vm.currentUser.medal +
                                '.svg'),"alt":"medal"}}):_vm._e(),(_vm.currentUser.payment_type)?_c('img',{attrs:{"src":require('@assets/images/icon-vip.svg'),"alt":"vip"}}):_vm._e(),(!_vm.currentUser.medal)?_c('md-button',{staticClass:"buy-medal uppercase",on:{"click":function () {
                                _vm.showMedalsModal = true;
                            }}},[_vm._v(_vm._s(_vm.$t('global.chooseMedal')))]):_vm._e()],1)])],1),_c('div',{staticClass:"content-right"},[_c('div',{staticClass:"pointer",staticStyle:{"display":"flex","flex-direction":"column"},on:{"click":function () {
                        _vm.showPriceModal = true;
                    }}},[_c('div',{staticClass:"rate"},[_vm._v(_vm._s(_vm.$t('route.rate')))]),_c('div',{staticClass:"rate-score"},[_c('div',[_vm._v(" "+_vm._s(_vm.currentUser.default_call_price)+"/"+_vm._s(_vm.currentUser.default_take_price)+" ")]),_c('div',[(_vm.rateUp)?_c('img',{attrs:{"src":require('@assets/images/icon_up.svg')}}):_c('img',{attrs:{"src":require('@assets/images/icon_down.svg')}}),_c('span',{class:{
                                green: _vm.rateUp,
                                red: !_vm.rateUp
                            }},[_vm._v(_vm._s(Math.round(_vm.currentUser.price_percent))+"%")])])])])])]),_c('div',[_c('price-modal',{attrs:{"show":_vm.showPriceModal},on:{"closePriceModal":function () { return (_vm.showPriceModal = false); }}}),_c('too-little-credit-modal',{attrs:{"show":_vm.showMinCreditModal,"carrierUUID":_vm.currentUser.carrierUUID},on:{"closeTooLittleCredit":function () { return (_vm.showMinCreditModal = false); }}}),_c('medals-modal',{attrs:{"show":_vm.showMedalsModal},on:{"close":function () { return (_vm.showMedalsModal = false); },"showMinCreditModal":function (e) { return (_vm.showMinCreditModal = e); }}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }