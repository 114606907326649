<template>
    <div>
        <div>
            <label
                class="pure-material-textfield-outlined"
                :class="{
                    error:
                        ($v.inputValue.label.$invalid &&
                            !$v.inputValue.label.minLength) ||
                        error
                }"
            >
                <input
                    placeholder=" "
                    type="text"
                    v-model="inputValue.label"
                    @input="onChange"
                    @focus="focus"
                    @blur="blur"
                    @keydown.enter="selectFirstItem"
                    @keydown.tab="selectFirstItem"
                />
                <span>{{ label }}</span>
            </label>
        </div>
        <div
            class="autocomplete"
            v-if="(isOpenAutocomplete && currentSource.length > 0) || loading"
        >
            <div v-if="!loading" class="autocomplete-list">
                <div
                    v-for="(item, index) in currentSource"
                    :key="item.lat + item.name + index + item.lng"
                    class="autocomplete-div"
                    @click="selectRegion(item)"
                >
                    <img :src="iconRenderer" />
                    <div class="autocomplete-item">
                        <div>
                            {{ item.label }}
                        </div>
                        <div>{{ item.region }}</div>
                    </div>
                </div>
            </div>

            <div v-if="loading"  class="autocomplete-list">
                <div class="autocomplete-div default-color">
                   
                        <img class="default-img" src="@assets/images/loading.gif" />
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import countries from '@assets/countries';

export default {
    props: {
        iconDefault: {
            type: Boolean,
            default: true
        },

        label: {
            type: String,
            default: ''
        },

        value: {
            type: String,
            default: ''
        },

        error: {
            type: Boolean,
            default: false
        }
    },

    validations: {
        inputValue: {
            label: { required, minLength: minLength(4) }
        }
    },

    data() {
        return {
            inputValue: {
                label: this.value
            },
            currentSource: [],
            selected: false,
            isOpenAutocomplete: false,
            loading: false,
            countyError: false
        };
    },

    watch: {
        value(e) {
            this.inputValue.label = e;
        }
    },

    computed: {
        iconRenderer() {
            return this.iconDefault
                ? require('@assets/images/icon_from.png')
                : require('@assets/images/icon_to.png');
        }
    },

    methods: {
        ...mapActions({
            postalCode: 'flotea/postalCode',
            autocomplete: 'flotea/autocomplete',
            search: 'flotea/search'
        }),

        onChange(evt) {
            this.inputValue.label = evt.target.value;

            if (this.selected) {
                this.keyEvent();
            }
        },

        focus() {
            this.selected = true;
            this.inputValue = {};
        },

        blur() {
            setTimeout(() => {
                this.selected = false;
                this.isOpenAutocomplete = false;

                if (this.selected) {
                    this.selectFirstItem();
                }
            }, 100);
        },

        selectFirstItem() {
            this.isOpenAutocomplete = false;
            const firstElement = this.currentSource.shift();

            if (firstElement !== undefined) {
                this.selectRegion(firstElement);
            }

            this.currentSource = [];
        },

        keyEvent() {
            if (this.inputValue.label) {
                this.isOpenAutocomplete = true;
                this.$emit('change', { isvalid: false });

                this.autoCompleteList(this.inputValue.label);
            } else {
                this.isOpenAutocomplete = false;
            }
        },

        async autoCompleteList(text) {
            const searchRespons = await this.search(text);
            const autoCompleteRespons = await this.autocomplete(text);
            const features = searchRespons.features.concat(
                autoCompleteRespons.features
            );

            let nfeatures = features.map(item => {
                return [item.properties.id, item];
            });

            nfeatures = new Map(nfeatures);

            this.currentSource = [];

            nfeatures.forEach(element => {
                let place = {
                    name: element.properties.name,
                    label: element.properties.label,
                    region: element.properties.region,
                    lat: element.geometry.coordinates[1],
                    lng: element.geometry.coordinates[0],
                    county: element.properties.country_a
                };

                this.currentSource.push(place);
            });
        },

        async selectRegion(item) {
            this.selected = true;
            this.loading = true;
            this.isOpenAutocomplete = false;
            const params = {
                id: item.name,
                cnt: countries[item.county],
                lat: item.lat,
                lng: item.lng
            };

            const response = await this.postalCode(params);

            if (response === undefined) {
                this.loading = false;
            } else {
                this.loading = false;
                this.inputValue = item;
                this.inputValue.label = response + ' ' + item.label;
                this.inputValue.postal = response;
                this.inputValue.isValid = true;
                this.$emit('change', this.inputValue);
            }
        }
    },

    mounted() {}
};
</script>
<style scoped>
.default-color{
    padding: 10px;
    background-color: white !important;
    justify-content: center !important;
}

.default-img{
    margin:0;
    width: 16px;
    height: 11px;
}
</style>