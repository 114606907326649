<template>
    <md-dialog :md-fullscreen="false" :md-active="show">
        <md-dialog-title>
            Kup medal
            <button
                type="button"
                class="md-button close-modal md-theme-default"
            >
                <div class="md-ripple closeX-default" @click="$emit('close')">
                    <div class="md-button-content">
                        x
                    </div>
                </div>
            </button>
        </md-dialog-title>
        <div class="payment-radio">
            <div class="md-radio">
                <md-radio
                    v-model="radio"
                    name="payment"
                    class="sel_credit w100p md-primary"
                    :value="890"
                >
                    <div>
                        <strong>
                            890 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span>
                            +
                            <span class="gold">
                                {{ $t('modals.golden') }}
                            </span>
                            {{ $t('modals.medal') }}
                            <br />
                        </strong>
                        <span class="small">
                            89 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 12
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            89 {{ $t('global.currency') }}
                        </span>
                    </div>
                </md-radio>
            </div>
            <div class="md-radio">
                <md-radio
                    v-model="radio"
                    name="payment"
                    class="sel_credit w100p  md-primary"
                    :value="690"
                >
                    <div>
                        <strong>
                            690 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span>
                            +
                            <span class="silver">
                                {{ $t('modals.silver') }}
                            </span>
                            {{ $t('modals.medal') }}
                            <br />
                        </strong>
                        <span class="small">
                            69 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 9
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            69 {{ $t('global.currency') }}
                        </span>
                    </div>
                </md-radio>
            </div>
            <div class="md-radio">
                <md-radio
                    v-model="radio"
                    name="payment"
                    class="sel_credit w100p  md-primary"
                    :value="490"
                >
                    <div>
                        <strong>
                            490 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span>
                            +
                            <span class="bronze">
                                {{ $t('modals.brown') }}
                            </span>
                            {{ $t('modals.medal') }}
                            <br />
                        </strong>
                        <span class="small">
                            49 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 6
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            49 {{ $t('global.currency') }}
                        </span>
                    </div>
                </md-radio>
            </div>
            <div class="md-radio" style="width:100%">
                <md-radio
                    v-model="radio"
                    name="payment"
                    class="sel_credit w100p  md-primary"
                    :value="190"
                >
                    <div>
                        <strong>
                            190 {{ $t('header.shortcutPoints') }}
                            <span class="vip">
                                VIP
                            </span>
                        </strong>
                        <br />
                        <span class="small">
                            19 {{ $t('global.currency') }},
                            {{ $t('modals.inquiry') }} 3
                            {{ $t('modals.minsEarlier') }}
                        </span>
                        <span class="only-for-iphone">
                            19 {{ $t('global.currency') }}
                        </span>
                    </div>
                </md-radio>
            </div>
        </div>
        <md-dialog-actions class="mt20">
            <md-button
                class="md-dense md-raised md-primary pull-right mt20"
                @click="buy"
            >
                {{ $t('modals.buyForPoints') }}
                <img
                    v-if="waitingBuy"
                    src="@assets/images/loader.gif"
                    alt="loader"
                    title="loader"
                />
            </md-button>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            waitingBuy: false,
            radio: false
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },

    methods: {
        ...mapActions({
            buyMedal: 'payments/buyMedal',
            getCarrier: 'auth/validate'
        }),

        async buy() {
            if (this.radio) {
                this.waitingBuy = true;
                const params = {
                    uuid: this.currentUser.carrierUUID,
                    url: window.location.href,
                    amount: this.radio
                };

                if (this.currentUser.credit < this.radio) {
                    this.$emit('close');
                    this.$emit('showMinCreditModal', true);
                    this.waitingBuy = false;

                    return;
                }

                try {
                    var response = await this.buyMedal(params);
                } catch (error) {
                    this.$toast.error(this.$t('global.somethingWrong'));
                }

                if (response) {
                    this.waitingBuy = false;
                    this.$emit('close');
                    this.getCarrier();
                }
            }
        }
    }
};
</script>
