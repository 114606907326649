<template>
    <md-dialog :md-fullscreen="false" :md-active="show" class="modal-price">
        <div style="position:relative;">
            <md-button @click="$emit('closePriceModal')" class="closeX">
                X
            </md-button>
        </div>

        <div class="text-center mt16 ml16 mr16 mb16">
            <div class="text-black uppercase">
                {{ $t('modals.tableOfRates') }}
            </div>
        </div>

        <div class="text-center ml16 mr16 mb16">
            <div class="text-black">
                {{ $t('modals.theRateIsResultOfYourEfficiency') }}
                <a :href="$t('modals.videoURL')" target="_blank">{{
                    $t('modals.SeeTheVideo')
                    }}</a>
                {{ $t('modals.howToPayLess') }}
            </div>
        </div>

        <div class="table-container">
            <div v-for="table in tables">
                <div class="table-container__title mt16">{{ table.title }}</div>
                <md-table>
                    <md-table-row>
                        <md-table-head>{{ $t('modals.level') }}</md-table-head>
                        <md-table-head>{{ $t('modals.rateCALL') }}</md-table-head>
                        <md-table-head>{{ $t('modals.rateTAKE') }}</md-table-head>
                        <md-table-head>{{ $t('modals.sum') }}</md-table-head>
                    </md-table-row>

                    <md-table-row v-for="item in table.items" :key="`item-${item.level}`">
                        <md-table-cell>{{ item.level }}</md-table-cell>
                        <md-table-cell
                        >{{ item.priceCal }}
                            {{ $t('global.currency') }}
                        </md-table-cell
                        >
                        <md-table-cell
                        >{{ item.priceTake }}
                            {{ $t('global.currency') }}
                        </md-table-cell
                        >
                        <md-table-cell
                        >{{ item.priceCal + item.priceTake }}
                            {{ $t('global.currency') }}
                        </md-table-cell
                        >
                    </md-table-row>
                </md-table>
            </div>
        </div>
    </md-dialog>
</template>
<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            tables: [
                {
                    title: 'Stawki podstawowe',
                    items: [
                        {level: '70% - 100%', priceCal: 3, priceTake: 4},
                        {level: '50% - 70%', priceCal: 4, priceTake: 4},
                        {level: '20% - 50%', priceCal: 6, priceTake: 3},
                        {level: '0% - 20%', priceCal: 7, priceTake: 4}
                    ]
                },
                {
                    title: 'Stawki ze srebrnym medalem 🥈',
                    items: [
                        {level: '70% - 100%', priceCal: 3, priceTake: 3},
                        {level: '50% - 70%', priceCal: 4, priceTake: 3},
                        {level: '20% - 50%', priceCal: 5, priceTake: 3},
                        {level: '0% - 20%', priceCal: 6, priceTake: 4}
                    ]
                },
                {
                    title: 'Stawki ze złotym medalem 🥇',
                    items: [
                        {level: '70% - 100%', priceCal: 3, priceTake: 2.5},
                        {level: '50% - 70%', priceCal: 4, priceTake: 2.5},
                        {level: '20% - 50%', priceCal: 5, priceTake: 2.5},
                        {level: '0% - 20%', priceCal: 6, priceTake: 3.5}
                    ]
                }
            ]

        }
    }
}
</script>
<style lang="scss" scoped>
.table-container {
    overflow: auto;
    height: 500px;
    &__title{
        text-align: center;
        font-size: 14px;
        font-weight: 900;
    }
}

</style>
