<template>
    <div class="mt20">
        <Carrier ref="carrierComponent" />
        <div
            class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
        >
            <md-card id="route">
                <md-card-header class="db">
                    <md-card-header-text>
                        <div
                            class="md-layout-item md-subhead md-xsmall-size-50 text-right"
                        >
                            <div class="absolut-wrapper">
                                <div class="row">
                                    <h2 class="light">
                                        {{ currentUser.commission_user }}
                                        {{ $t('global.currency') }}
                                    </h2>
                                    <small class="c9c9c9c"
                                        >{{ $t('transfer.deposit') }} 50%
                                    </small>
                                </div>
                            </div>
                        </div>
                    </md-card-header-text>
                    <md-card-header-text>
                        <div
                            class="md-layout-item md-subhead  md-xsmall-size-100"
                        >
                            <h2 class="uppercase light">
                                {{ $t('transfer.transferPassenger') }}
                            </h2>
                            <small class="c9c9c9c">{{
                                $t('transfer.sendInquiry')
                            }}</small>
                        </div>
                    </md-card-header-text>
                </md-card-header>
                <form autocomplete="off">
                    <md-card-content>
                        <autocomplete-input
                            :label="$t('transfer.from')"
                            :value="this.from.label"
                            :error="countyError"
                            @change="showEfectsFrom"
                        />
                    </md-card-content>

                    <md-card-content>
                        <autocomplete-input
                            :iconDefault="false"
                            :label="$t('transfer.to')"
                            :value="this.to.label"
                            :error="countyError"
                            @change="showEfectsTo"
                        />
                    </md-card-content>

                    <md-card-content>
                        <intl-tel
                            ref="intlTel"
                            :label="$t('transfer.phoneNumber')"
                            v-on:change="showEfects"
                        />
                    </md-card-content>
                    <md-card-content>
                        <div class="form-in-line" style="width:100%;">
                            <div class="input-padding">
                                <label class="pure-material-textfield-outlined">
                                    <input
                                        class="datapicker"
                                        placeholder=" "
                                        type="date"
                                        v-model="date"
                                    />
                                    <span
                                        >{{ $t('transfer.dateOfDeparture') }}
                                    </span>
                                </label>
                            </div>

                            <div class="select-padding">
                                <label class="pure-material-textfield-outlined">
                                    <select
                                        placeholder=" "
                                        name="cars"
                                        id="cars"
                                        v-model="passengers"
                                    >
                                        <option v-for="n in 10" :key="n">{{
                                            n
                                        }}</option>
                                    </select>
                                    <span>{{ $t('transfer.passengers') }}</span>
                                </label>
                            </div>
                        </div>
                    </md-card-content>

                    <md-card-content class="hr pt10 mt20">
                        <div class="settings-notifications" style="width:100%">
                            <small class="c9c9c9c">{{
                                $t('transfer.additionalOptions')
                            }}</small>
                            <div v-for="(item, index) in 7" :key="item">
                                <ul class="list-transfer">
                                    <div class="subtitle">
                                        <div>
                                            <img
                                                :src="
                                                    require(`@assets/images/${arrayOfIcons[index].icon}`)
                                                "
                                                alt="info"
                                            />
                                            {{ arrayOfIcons[index].info }}
                                        </div>
                                    </div>
                                    <md-switch v-model="attributes[index]" />
                                </ul>
                                <md-divider class="md-inset" />
                            </div>
                        </div>
                    </md-card-content>
                    <md-card-content>
                        <md-button
                            class="btn-default-blue mt16"
                            style="margin-left:0;"
                            @click="submit"
                        >
                            {{ $t('transfer.transferButton') }}
                        </md-button>
                    </md-card-content>
                </form>
            </md-card>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Carrier from '../components/elements/Carrier';
import AutocompleteInput from '../components/elements/AutocompleteInput';
import '@matthew2097/intl-tel-material';

export default {
    components: {
        Carrier,
        AutocompleteInput
    },

    data() {
        return {
            countyError: false,
            passengers: '1',
            phoneNumber: '',
            from: { isValid: false, label: '' },
            to: { isValid: false, label: '' },
            date: '',
            isValidPhoneNumber: null,
            attributes: [0, 0, 0, 0, 0, 0, 0],
            arrayOfIcons: [
                {
                    message: this.$t('global.attributes.consignment'),
                    info: this.$t('transfer.pack'),
                    icon: 'icon_box.png'
                },
                {
                    message: this.$t('global.attributes.pet'),
                    info: this.$t('transfer.pet'),
                    icon: 'icon_pet.png'
                },
                {
                    message: this.$t('global.attributes.wheelchair'),
                    info: this.$t('transfer.wheelchair'),
                    icon: 'icon_wheelchair.png'
                },
                {
                    message: this.$t('global.attributes.otherDay'),
                    info: this.$t('transfer.otherDay'),
                    icon: 'icon-calendar-plus.png'
                },
                {
                    message: this.$t('global.attributes.returnJourney'),
                    info: this.$t('transfer.round-trip'),
                    icon: 'icon-return.png'
                },
                {
                    message: this.$t('global.attributes.morning'),
                    info: this.$t('transfer.morning'),
                    icon: 'icon-sun.png'
                },
                {
                    message: this.$t('global.attributes.night'),
                    info: this.$t('transfer.evening'),
                    icon: 'icon-moon.png'
                }
            ]
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },

    methods: {
        ...mapActions({
            getCarrier: 'auth/validate',
            sendPassenger: 'flotea/sendPassenger'
        }),

        showEfects(e) {
            const { isValid, value } = e.detail;
            this.isValidPhoneNumber = isValid;

            if (isValid) {
                this.phoneNumber = value;
            }
        },

        showEfectsFrom(e) {
            this.countyError = false;
            this.from = e;
        },

        showEfectsTo(e) {
            this.countyError = false;
            this.to = e;
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async clearForms() {
            this.from = { isValid: false, label: '' };
            this.to = { isValid: false, label: '' };
            this.attributes = [1, 0, 0, 0, 0, 0, 0];
            this.phoneNumber = '';
            this.passengers = '1';

            await this.$refs.intlTel.clear();
        },

        isValid() {
            const { from, to, isValidPhoneNumber } = this;

            if (from.isValid && to.isValid && isValidPhoneNumber) {
                return true;
            } else {
                return false;
            }
        },

        async submit() {
            const attributes = this.attributes.map(x => {
                return x === true ? 1 : 0;
            });

            attributes.unshift(parseInt(this.passengers));

            if (!this.isValid()) {
                this.$toast.error(this.$t('transfer.completeForms'));

                return;
            }

            if (this.from.county === this.to.county) {
                this.countyError = true;

                this.$toast.error(
                    this.$t(
                        'transfer.departureAndArrivalFromDifferentCountries'
                    )
                );

                return;
            }

            if (!this.isValidPhoneNumber) {
                this.$toast.error(this.$t('transfer.enterValidPhone'));

                return;
            }

            const params = {
                passenger: [
                    {
                        count: parseInt(this.passengers),
                        phone: this.phoneNumber,
                        city_from: this.from.label,
                        pos_from: {
                            lat: this.from.lat,
                            lng: this.from.lng
                        },
                        city_to: this.to.label,
                        pos_to: {
                            lat: this.to.lat,
                            lng: this.to.lng
                        },
                        attributes,
                        when: moment(this.date).format('YYYY-MM-DD'),
                        email: this.currentUser.email,
                        partner: this.currentUser.carrierUUID
                    }
                ]
            };

            const response = await this.sendPassenger(params);

            if (!response.valid) {
                if (
                    response.errors[0] ===
                    'Multiple requests are locked. Update success.'
                ) {
                    this.$toast.error(
                        this.$t('transfer.alreadySentThisInquiry')
                    );
                } else {
                    this.$toast.error(this.$t('global.somethingWrong'));
                }
            } else {
                this.$toast.success(
                    this.$t('transfer.passengerHasBeenHandedOver')
                );
                this.clearForms();
            }
        }
    },

    mounted() {
        this.scrollToTop();
        this.getCarrier();
        this.date = moment().format('YYYY-MM-DD');
    }
};
</script>
